import { http } from '@/http/index'

async function sendTextMessage(threadId, data) {
	return http.post(`/messenger/threads/${threadId}/messages`, data).then((response) => response.data)
}

async function sendImageMessage(threadId, data) {
	return http.post(`/messenger/threads/${threadId}/images`, data).then((response) => response.data)
}

async function sendVideoMessage(threadId, data) {
	return http.post(`/messenger/threads/${threadId}/videos`, data).then((response) => response.data)
}

async function sendMultimediaMessage(threadId, data) {
	return http.post(`/messenger/threads/${threadId}/multimedias`, data).then((response) => response.data)
}

export { sendTextMessage, sendImageMessage, sendVideoMessage, sendMultimediaMessage }
