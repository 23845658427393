import { defineStore } from 'pinia'
import girlApi from '@/http/girl'
import posts, { getMoreData } from './helper'

export const useGirlStore = defineStore('girl', {
	state: () => ({
		girlId: null,
		profile: null,
		posts: null,
		filteredPosts: null,
		selectedFilter: 'total',
		filterNextLink: { total: '', all: '', fans: '' },
		favoriteComments: null,
		fans: null,
		likes: null,
		isLoading: false
	}),
	getters: {
		postsHasLoaded: (state) => state.posts !== null,
		favoriteCommentsHasLoaded: (state) => state.favoriteComments !== null
	},
	actions: {
		updateLikes(girl, post) {
			if (this.profile) {
				this.profile.total_likes = girl.total_likes
			}
			posts.findPostAndUpdateLikesValue(this.posts, post)
		},
		async getLikes(girlId) {
			if (this.likes === null) {
				try {
					this.isLoading = true
					this.likes = await girlApi.getLikes(girlId)
				} finally {
					this.isLoading = false
				}
			}
		},
		async getMoreLikes() {
			await getMoreData(this.likes, girlApi.getMoreLikes)
		},
		async getFans(girlId) {
			if (this.fans === null) {
				try {
					this.isLoading = true
					this.fans = await girlApi.getFans(girlId)
				} finally {
					this.isLoading = false
				}
			}
		},
		async getMoreFans() {
			await getMoreData(this.fans, girlApi.getMoreFans)
		},
		async subscribe(girlId) {
			this.isLoading = true
			await girlApi.subscribe(girlId)
			this.isLoading = false
		},
		async unsubscribe(girlId) {
			this.isLoading = true
			try {
				await girlApi.unsubscribe(girlId)
			} finally {
				this.isLoading = false
			}
		},
		incrementTotalFans() {
			if (this.profile) {
				this.profile.total_fans += 1
			}
		},
		decrementTotalFans() {
			if (this.profile) {
				this.profile.total_fans -= 1
			}
		},
		incrementTotalFollowers() {
			if (this.profile) {
				this.profile.total_followers += 1
			}
		},
		decrementTotalFollowers() {
			if (this.profile) {
				this.profile.total_followers -= 1
			}
		},
		toggleFollowingState() {
			if (this.profile) {
				this.profile.acting_consumer_is_follower = !this.profile.acting_consumer_is_follower
			}
		},
		toggleSubscriptionState() {
			if (this.profile) {
				this.profile.acting_consumer_is_subscribed = !this.profile.acting_consumer_is_subscribed
				this.profile.waiting_subscription_result = !this.profile.waiting_subscription_result
			}
		},
		applyBlurToPostsOnlyForFans(girlId) {
			if (this.posts) {
				posts.mediaContentVisibleOnlyForFans(this.posts.data, girlId)
			}
		},
		removeBlurFromPosts(girlId) {
			if (this.posts) {
				posts.medialContentVisibleForAllConsumers(this.posts.data, girlId)
			}
		},
		turnOnWaitingSubscription(girlId) {
			if (this.posts) {
				posts.turnOnWaitingSubscription(this.posts.data, girlId)
			}
		},
		async getGirlProfile(girlId, refresh = false) {
			try {
				if (this.profile === null || refresh) {
					this.isLoading = true
					this.profile = await girlApi.getProfile(girlId)
				}
			} catch (e) {
				throw new Error(e)
			} finally {
				this.isLoading = false
			}
		},
		async getGirlProfileByNickname(nickname) {
			try {
				if (this.profile === null) {
					this.isLoading = true
					this.profile = await girlApi.getProfileByNickname(nickname)
				}
				// eslint-disable-next-line
			} catch (error) {
				throw error
			} finally {
				this.isLoading = false
			}
		},
		async getPosts(girlId) {
			try {
				this.isLoading = true
				if (this.posts === null) {
					this.posts = await girlApi.getPosts(girlId)
					this.filteredPosts = this.posts.data
					this.prepareFilterLinks()
				}
			} finally {
				this.isLoading = false
			}
		},
		prepareFilterLinks() {
			if (this.posts.links.next) {
				Object.keys(this.filterNextLink).forEach((key) => {
					const temp = this.posts?.links?.next.split('/audience/')[0]
					this.filterNextLink[key] = `${temp}/audience/${key}`
					if (this.posts.links.next.includes('total?page=2')) {
						this.filterNextLink.total = `${temp}/audience/total?page=2`
					}
				})
			}
		},
		async filterPosts(val) {
			this.selectedFilter = val
			if (val === 'total') {
				this.filteredPosts = this.posts?.data
			} else {
				this.filteredPosts = this.posts?.data.filter((post) => post.audience === val)
				if (this.posts.links.next && this.filteredPosts.length < 7) {
					this.getMorePosts()
				}
			}
		},
		async getMorePosts() {
			this.isLoading = true
			this.posts.links.next = this.filterNextLink[this.selectedFilter]
			try {
				await getMoreData(this.posts, girlApi.getMorePosts)
				this.filterNextLink[this.selectedFilter] = this.posts.links.next
			} finally {
				this.filterPosts(this.selectedFilter)
				this.isLoading = false
			}
		},
		async getFavoriteComments(girlId) {
			if (this.favoriteComments === null) {
				try {
					this.isLoading = true
					this.favoriteComments = await girlApi.getFavoriteComments(girlId)
				} finally {
					this.isLoading = false
				}
			}
		},
		async getMoreFavoriteComments() {
			await getMoreData(this.favoriteComments, girlApi.getMoreFavoriteComments)
		},
		removePostFromList(postId) {
			posts.removePostFromList(this.posts, postId)
		},
		async sendComplaint(girlId) {
			await girlApi.sendComplaint(girlId)
		},
		async resetStore() {
			this.$reset()
		}
	}
})
