<template>
	<dialog-component @close="emit('close')">
		<template #header-title>{{ $t('verification.qrCodeTitle') }}</template>
		<img :src="qrCode" :alt="$t('verification.qrCodeAlt')" class="qrcode-img" />
		<p class="qrcode-description">{{ $t('verification.qrCodeDescription') }}</p>
		<button-component color="primary" class="qrcode-btn" @click="copyQrCodeToClipboard()">
			{{ $t('verification.qrCodeCopy') }}
		</button-component>
	</dialog-component>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { copyToClipboard } from '@/helpers/copyToClipboard'
import { useCurrentUserStore } from '@/stores/user'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import verificationApi from '@/http/girl/verification'

const qrCode = ref('')
const link = ref('')

const userStore = useCurrentUserStore()
const emit = defineEmits(['close'])

onMounted(async () => {
	const result = await verificationApi.qrGenerate(userStore.currentUser.id)
	qrCode.value = result.qr_code
	link.value = result.link
})

async function copyQrCodeToClipboard() {
	copyToClipboard(link.value, 'linkCopied')
	emit('close')
}
</script>

<style lang="scss" scoped>
:deep(.dialog-body) {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.qrcode-img {
	width: 358px;
	height: 358px;
}

.qrcode {
	&-description {
		font-size: 16px;
		line-height: 130%;
		font-weight: 400;
		text-align: center;
		color: $color-gray;
		margin-top: 24px;
		margin-bottom: 30px;
	}
	&-btn {
		max-width: 220px;
	}
}
</style>
