import { mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useProfileStore } from '@/stores/profile'
import { useConsumerStore } from '@/stores/consumer'
import { useGirlProfileStore } from '@/stores/girlProfile'

export default {
	computed: {
		...mapState(useGirlProfileStore, { ownGirlId: 'id' }),
		...mapState(useProfileStore, { ownConsumerId: 'id' }),
		...mapState(useGirlStore, { girlProfile: 'profile' }),
		...mapState(useConsumerStore, { consumerProfile: 'profile' }),
		$_isOwnProfile() {
			if (this.girlProfile) {
				return this.girlProfile?.id === this.ownGirlId
			}
			return this.consumerProfile?.id === this.ownConsumerId
		}
	}
}
