<template>
	<div class="total-likes flex align">
		<span class="like-count">
			<slot />
		</span>
		<div class="like-icon active" />
	</div>
</template>

<script>
export default {
	name: 'LikeCounter'
}
</script>

<style>
.like-count {
	font-size: 14px;
	line-height: 14px;
	font-weight: bold;
	margin-right: 6px;
}

.total-likes {
	margin-left: 20px;
}
</style>
