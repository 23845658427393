<template>
	<div class="user-with-likes">
		<div class="flex row start w-full align">
			<div class="avatar-circle placeholder" />
			<div class="user-with-likes-name placeholder w-75" />
			<div class="total-likes flex align placeholder w-25" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'PlaceholderUserWithLikes'
}
</script>
<style lang="scss" scoped>
.avatar-circle {
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
}
</style>
