<template>
	<div @click="onClick" class="schedule-list-wrap">
		<div v-if="isLoading">
			<div v-if="!isMobileDevice" class="schedule-list-date placeholder-bg" />
			<div class="total placeholder-bg" />
		</div>
		<div v-else>
			<div v-if="selectedDay" class="schedule-list-date">{{ selectedDay }}</div>
			<div v-if="scheduleByDay?.length" class="total">
				{{ $t('publicationsScheduled') }}: <span class="count">{{ scheduleByDay?.length || 0 }}</span>
			</div>
			<div v-else class="none" v-html="selectedDay ? $t('noPublicationsScheduled') : $t('datepicker.chooseDate')" />
		</div>
		<div v-if="isLoading">
			<placeholder-schedule-events v-for="i in isMobileDevice ? 3 : 4" :key="`pse${i}`" />
		</div>
		<div v-else class="schedule-list">
			<div v-for="(item, index) in scheduleByDay" :key="item.id">
				<div :class="['schedule-item', `schedule-item--${item.publication_status}`]">
					<div class="schedule-item-time">{{ item.should_be_published_at || $moment('HH:mm') }}</div>
					<div
						:data-publication-status="item.publication_status"
						@click="openDetailPage(item.id, item.publication_status)"
						class="schedule-body"
					>
						<div class="schedule-img-wrap">
							<img
								alt=""
								:src="
									item?.attachments?.photos
										? item?.attachments?.photos[0].thumbnail || item?.attachments?.photos[0].url
										: item?.attachments?.video?.preview?.thumbnail || item?.attachments?.video?.preview?.url
								"
								class="schedule-img"
							/>
							<svg-icon v-if="item?.attachments?.type === 'video'" name="play-icon" size="20px" />
						</div>
						<div class="schedule-body-descr">
							<div class="schedule-body-top">
								<div
									class="schedule-body-status"
									@click="item.publication_status === 'rejected' ? rejectDialogClick(index) : null"
								>
									{{ getStatus(item.publication_status) }}
								</div>
								<div v-if="showMore" class="more" @click="(e) => actionsDialogClick(e, item)" />
							</div>
							<div v-if="item.audience === 'fans'" class="for-fans"><badge-fan />{{ $t('post.forFans') }}</div>
							<div class="schedule-body-text">
								<text-with-hashtags-and-mentioned
									v-if="item.description"
									class="post-description"
									:preview-letters-count="60"
									:text="item.description"
									:hashtags="item?.hashtags"
									:mentioned="item?.mentioned"
									:expand-text="false"
								/>
								<div v-else class="text-default">{{ $t('post.noDescription') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<dialog-component v-if="showRejectDialog" @close="showRejectDialog = false">
			<template #header-title>{{ $t('post.decline') }}</template>
			<div class="text-red mb-16">{{ scheduleByDay[itemIndex]?.rejection_reason }}</div>
			<div>{{ $t('post.retryModeration') }}</div>
		</dialog-component>
		<dialog-component v-if="showActionsDialog" @close="showActionsDialog = false">
			<div @click="onEdit" class="dialog-link">{{ $t('toEdit') }}</div>
			<div @click="confirmDeletePost" class="dialog-link dialog-link--red" v-if="postItem.can_be_deleted">
				{{ $t('post.delete') }}
			</div>
		</dialog-component>
		<dialog-component v-if="showDeleteDialog" @close="showDeleteDialog = false">
			<div>{{ $t('post.confirmDelete') }}</div>
			<ButtonComponent :loading="isSending" @click="onDelete" color="primary-outline mt-20">
				{{ $t('delete') }}
			</ButtonComponent>
		</dialog-component>
	</div>
</template>

<script>
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapActions, mapState } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import { useModalStore } from '@/stores/modal'
import { usePostStore } from '@/stores/post'
import SvgIcon from '@/components/SvgIcon.vue'
import PlaceholderScheduleEvents from '@/components/placeholders/PlaceholderScheduleEvents.vue'
import { useCommonStore } from '@/stores/common'
import BadgeFan from '@/components/BadgeFan.vue'
import eventHelper from '@/helpers/event.helper.ts'

export default {
	name: 'ScheduleDayEvents',
	components: {
		BadgeFan,
		ButtonComponent,
		DialogComponent,
		SvgIcon,
		TextWithHashtagsAndMentioned,
		PlaceholderScheduleEvents
	},
	props: {
		scheduleByDay: { type: Array, default: () => [] },
		showMore: { type: Boolean, default: true },
		isLoading: { type: Boolean, default: false },
		selectedDay: { type: String, default: '' }
	},
	data() {
		return {
			showRejectDialog: false,
			showActionsDialog: false,
			showDeleteDialog: false,
			isSending: false,
			itemIndex: null,
			postItem: null
		}
	},
	computed: {
		...mapState(useCommonStore, ['isMobileDevice']),
		type() {
			if (
				this.postItem?.attachments &&
				this.postItem?.attachments?.photos &&
				this.postItem?.attachments?.type === 'photo'
			) {
				return this.postItem?.attachments?.photos.length > 1 ? 'carousel' : 'image'
			}
			return 'video'
		}
	},
	methods: {
		...mapActions(useCreatePostStore, { setPost: 'setPost' }),
		...mapActions(usePostStore, { deletePost: 'deletePost' }),
		...mapActions(useModalStore, { openDetailPage: 'openDetailPage' }),
		getStatus(status) {
			const statuses = {
				in_moderation: this.$t('post.inProgress'),
				published: this.$t('post.published'),
				rejected: this.$t('decline'),
				scheduled: this.$t('post.scheduled'),
				default: ''
			}
			return statuses[status] || statuses.default
		},
		rejectDialogClick(index) {
			this.showRejectDialog = true
			this.itemIndex = index
		},
		actionsDialogClick(e, postItem) {
			e.preventDefault()
			e.stopPropagation()
			this.postItem = postItem

			eventHelper.emit('setPopupData', {
				popup: 'schedulePostOptions',
				position: JSON.parse(JSON.stringify(e.target.getBoundingClientRect())),
				dropdownMode: true,
				postItem
			})
		},
		confirmDeletePost() {
			this.showDeleteDialog = true
		},
		onClick() {
			this.$emit('click')
		},
		onEdit() {
			let post = {}
			if (this.type === 'image') {
				post = {
					photo_id: this.postItem?.attachments?.photos[0]?.id,
					photo: this.postItem?.attachments?.photos[0]?.url,
					description: this.postItem?.description,
					audience: this.postItem?.audience,
					should_be_published_at: this.postItem?.should_be_published_at,
					can_be_scheduled: this.postItem?.can_be_scheduled
				}
			}
			if (this.type === 'video') {
				post = {
					video_id: this.postItem?.attachments?.video?.id,
					video: this.postItem?.attachments?.video?.file,
					video_preview_id: this.postItem?.attachments?.video?.preview?.id,
					description: this.postItem?.description,
					audience: this.postItem?.audience,
					should_be_published_at: this.postItem?.should_be_published_at,
					can_be_scheduled: this.postItem?.can_be_scheduled
				}
			}
			this.setPost(post, this.postItem?.id, 'edit')
			this.showActionsDialog = false
		},
		async onDelete() {
			this.isSending = true
			await this.deletePost(this.postItem?.id)
			this.showDeleteDialog = false
			this.showActionsDialog = false
			this.isSending = false
			this.$emit('delete')
		}
	},
	mounted() {
		eventHelper.on('editSchedulePostOption', (postId) => {
			if (postId === this.postItem?.id) {
				this.onEdit()
			}
		})
		eventHelper.on('deleteSchedulePostOption', (postId) => {
			if (postId === this.postItem?.id) {
				this.onDelete()
			}
		})
	}
}
</script>

<style lang="scss">
.schedule-list-wrap {
	& .schedule-list-date {
		display: none;
		font-size: 14px;
		text-align: center;
		color: $color-semi-transparent;
		padding: 4px 0 0 0;
		&:first-letter {
			text-transform: uppercase;
		}
		@media (min-width: $screen-desktop) {
			display: block;
			&.placeholder-bg {
				margin: 0 auto;
				width: 60%;
				height: 16px;
			}
		}
	}
	& .total {
		margin: 15px 0;
		font-size: 16px;
		color: $color-gray;
		& span {
			color: $color-white;
		}
		&.placeholder-bg {
			width: 75%;
			height: 20px;
			@media (min-width: $screen-desktop) {
				&.placeholder-bg {
					margin: 7px auto 15px auto;
				}
			}
		}
	}
	& .none {
		text-align: center;
		padding: 15px 0;
		font-size: 16px;
		color: $color-gray;
	}
	& .schedule-item {
		padding-bottom: 10px;
		&-time {
			font-size: 16px;
			color: $color-white;
			margin-bottom: 4px;
			&.placeholder-bg {
				width: 15%;
				height: 20px;
			}
		}
		& .schedule-body {
			display: flex;
			padding: 8px;
			background-color: $color-black-additional;
			border-radius: 11px;
			min-height: 104px;
			& .schedule-img-wrap {
				position: relative;
				margin-right: 10px;
				min-width: 84px;
				& .schedule-img {
					object-fit: cover;
					max-width: 84px;
					min-width: 84px;
					max-height: 84px;
					border-radius: 8px;
				}
				& .svg-icon {
					position: absolute;
					top: 10px;
					left: 10px;
					opacity: 0.7;
				}
			}
			&-descr {
				flex: 1 1 100%;
			}
			&-top {
				display: flex;
				justify-content: space-between;
				margin-bottom: 7px;
			}
			&-status {
				display: flex;
				align-items: center;
				font-size: 14px;
				color: $color-semi-transparent;
				& img {
					margin-left: 5px;
				}
				&.placeholder-bg-in {
					width: 45%;
					height: 17px;
				}
			}
			& .for-fans {
				display: inline-flex;
				align-items: center;
				font-size: 12px;
				padding: 2px 5px 2px 0;
				margin-bottom: 8px;
				background-color: $color-blue-dark;
				border-radius: 10px;
			}
			&-text {
				font-size: 14px;
				color: $color-white;
				& .post-description {
					padding: 0;
					line-height: 14px;
				}
				&.placeholder-bg-in {
					width: 100%;
					height: 17px;
					&.second {
						margin-top: 5px;
						width: 85%;
					}
				}
			}
			& .text-default {
				color: $color-semi-transparent;
			}
		}
		&--published {
			& .schedule-item-time {
				color: $color-semi-transparent;
			}
			& .schedule-body-status {
				color: $color-white;
			}
		}
		&--scheduled {
			& .schedule-body-status {
				color: $color-green-additional-secondary;
			}
		}
		&--rejected {
			& .schedule-body-status {
				color: $color-red-additional;
			}
		}
	}
	.placeholder-bg {
		border-radius: 6px;
	}
	.placeholder-bg-in {
		border-radius: 6px;
	}
}
.word-break_fade::before {
	background: linear-gradient(to right, transparent, $color-black-additional 80%, $color-black-additional 100%);
}
.dialog-link {
	display: block;
	padding: 10px 0;
	font-size: 16px;
	font-weight: 500;
	color: $color-white;
	cursor: pointer;
	transition: $transition-color;
	&:hover {
		color: $color-white-hover;
	}
	&:active {
		color: $color-white-active;
	}

	&:only-child {
		padding: 0;
	}
}
.dialog-link--red {
	color: $color-magenta;
	&:hover {
		color: $color-magenta-hover;
	}
	&:active {
		color: $color-magenta-active;
	}
}
</style>
