import { addMethod, string, mixed } from 'yup'
import { i18n } from '@/plugins/i18n'

addMethod(string, 'password', function a(message) {
	return this.test('password', message, function b(value) {
		const { path, createError } = this
		if (!/^(?=.*[a-z])(?=.*[A-Z])/.test(value)) {
			return createError({
				path,
				message: i18n.global.t('error.passwordLetters')
			})
		}
		if (!/(?=.*\d)/.test(value)) {
			return createError({
				path,
				message: i18n.global.t('error.passwordNumber')
			})
		}

		return true
	})
})

addMethod(string, 'min', function a(min, message) {
	return this.test('min', message, function b(value) {
		const { path, createError } = this
		if (value.length >= min || !value) return true
		return createError({
			path,
			message: message ? message({ min }) : i18n.global.t('error.minDefault', { min })
		})
	})
})

addMethod(mixed, 'ext', function a(extArray, message) {
	return this.test('ext', message, function b(file) {
		const { path, createError } = this
		if (!file) return true
		if (!file.type) {
			return createError({
				path,
				message: i18n.global.t('error.unknownType')
			})
		}

		const formattedExt = extArray.map((e) => e.toLowerCase())
		if (extArray.includes('mov')) formattedExt.push('quicktime')
		const fileExt = file.type.split('/')[1].toLowerCase()

		const hasFormat = formattedExt.includes(fileExt)
		if (hasFormat) return true

		const joinExt = extArray.map((e) => e.toUpperCase()).join(', ')
		const defaultMessage = i18n.global.t(`error.postMediaFormatTitle`, { ext: joinExt })
		return createError({
			path,
			message: message ? message({ defaultMessage, ext: joinExt }) : defaultMessage
		})
	})
})

addMethod(mixed, 'aspectRatio', function a(ar, message) {
	return this.test('aspectRatio', message, function b(file) {
		const { path, createError } = this
		const { width, height } = file
		if (width && height) {
			if (Math.max(width, height) / Math.min(width, height) < ar) return true
			const defaultMessage = i18n.global.t('error.aspectRatio')
			return createError({
				path,
				message: message ? message({ defaultMessage }) : i18n.global.t(defaultMessage)
			})
		}
		return true
	})
})

addMethod(mixed, 'size', function a(size, message) {
	return this.test('size', message, function b(file) {
		const { path, createError } = this
		if (file.size < size) return true
		const defaultMessage = i18n.global.t(`error.sizeDefault`, { size: size / 1000000 })
		return createError({
			path,
			message: message ? message({ size }) : defaultMessage
		})
	})
})

addMethod(mixed, 'imageSize', function a(size, message) {
	return this.test('imageSize', message, function b(file) {
		const { path, createError } = this
		if (!file) return true
		const isImage = file.type.split('/')[0] === 'image'
		if (!isImage) return true
		if (file.size < size) return true
		const defaultMessage = i18n.global.t(`error.imageSizeDefault`, { size: size / 1000000 })
		return createError({
			path,
			message: message ? message({ defaultMessage, size }) : defaultMessage
		})
	})
})

addMethod(mixed, 'videoSize', function a(size, message) {
	return this.test('videoSize', message, function b(file) {
		const { path, createError } = this
		const isVideo = file.type.split('/')[0] === 'video'
		if (!isVideo) return true
		if (file.size < size) return true
		const defaultMessage = i18n.global.t(`error.videoSizeDefault`, { size: size / 1000000 })
		return createError({
			path,
			message: message ? message({ size }) : defaultMessage
		})
	})
})

addMethod(string, 'noLinksOrEmail', function a(message) {
	return this.test('noLinksOrEmail', message, function b(value) {
		const { path, createError } = this
		const error = createError({
			path,
			message: i18n.global.t('error.noLinksOrEmail')
		})
		if (!value) return true
		if (value.includes('@')) return error
		if (
			/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))\S){2,})|((?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))\S){2,})\.[a-z]{1,3}\b)+)/gim.test(
				value
			)
		) {
			return error
		}
		return true
	})
})

addMethod(mixed, 'audioSize', function a(size, message) {
	return this.test('audioSize', message, function b(file) {
		const { path, createError } = this
		const isAudio = file.type.split('/')[0] === 'audio'
		if (!isAudio) return true
		if (file.size < size) return true
		return createError({
			path,
			message: message ? message({ size }) : i18n.global.t('error.audioSizeDefault')
		})
	})
})

addMethod(mixed, 'minDimensions', function a([width, height], message) {
	return this.test('minDimensions', message, function b(file) {
		const { path, createError } = this
		if (file.type.includes('video') || (file.width >= width && file.height >= height)) return true
		const defaultMessage = i18n.global.t('error.minDimensionsDefault', { width, height })
		return createError({
			path,
			message: message
				? message({
						defaultMessage,
						width,
						height
					})
				: defaultMessage
		})
	})
})

addMethod(mixed, 'minVideoDuration', function a(minDuration, message) {
	return this.test('minVideoDuration', message, function b(file) {
		const { path, createError } = this
		const isVideo = file.type.split('/')[0] === 'video'
		if (!isVideo) return true
		if (file.duration > minDuration) return true
		const defaultMessage = i18n.global.t('error.minVideoDurationDefault', { minDuration })
		return createError({
			path,
			message: message ? message({ defaultMessage, minDuration }) : defaultMessage
		})
	})
})

addMethod(mixed, 'maxVideoDuration', function a(maxDuration, message) {
	return this.test('maxVideoDuration', message, function b(file) {
		const { path, createError } = this
		const isVideo = file.type.split('/')[0] === 'video'
		if (!isVideo) return true
		if (maxDuration >= Math.trunc(file.duration)) return true
		const defaultMessage = i18n.global.t('error.maxVideoDurationDefault', { maxDuration })
		return createError({
			path,
			message: message ? message({ defaultMessage, maxDuration }) : defaultMessage
		})
	})
})

addMethod(string, 'noOnlySpaces', function a(message) {
	return this.test('noOnlySpaces', message, function b(value) {
		const { path, createError } = this
		if (!value) return true
		if (/.*\S.*/gm.test(value)) {
			return true
		}
		return createError({
			path,
			message: message ?? i18n.global.t('error.noOnlySpacesDefault')
		})
	})
})
