<template>
	<div class="language">
		<img :src="flagUrl" alt="flag" />
		<span> {{ t(localeKey) }}</span>
	</div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCommonStore } from '@/stores/common'

const { t } = useI18n()
const { localeMap } = useCommonStore()

const { locale } = defineProps({
	locale: {
		type: String,
		required: true
	}
})

const flagUrl = computed(() => {
	const images = import.meta.glob('/src/assets/images/icons/flag/*.png', { eager: true })
	const path = `/src/assets/images/icons/flag/${locale}.png`

	return images[path]?.default || ''
})
const localeKey = computed(() => `locale.${localeMap.get(locale)}`)
</script>

<style lang="scss" scoped>
.language {
	display: flex;
	flex-flow: row nowrap;
	column-gap: 12px;
	align-items: center;
	img {
		width: 26px;
		height: 26px;
	}
}
</style>
