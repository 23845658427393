<template>
	<button type="button" class="following-btn" @click="onClick">
		<svg-icon name="spinner" size="16px" v-if="isProcessing" class="rotating" />
		<template v-else>
			{{ isFollow ? $t('subscription.unsubscribe') : $t('subscription.subscribe') }}
		</template>
	</button>
</template>

<script>
import { mapActions } from 'pinia'
import { useFollowingStore } from '@/stores/consumer/following/index'
import { useProfileStore } from '@/stores/profile'
import { useModalStore } from '@/stores/modal'
import SvgIcon from '@/components/SvgIcon.vue'
import subscriptionsMixin from '@/mixins/subscriptionsMixin'

export default {
	name: 'FollowingButton',
	components: { SvgIcon },
	mixins: [subscriptionsMixin],
	props: {
		isFollow: { type: Boolean, required: true, default: false },
		girlId: { type: Number, required: true }
	},
	data() {
		return {
			isProcessing: false
		}
	},
	methods: {
		...mapActions(useProfileStore, {
			getFollowingGirls: 'getFollowingGirls'
		}),
		...mapActions(useModalStore, {
			openUnfollowDialog: 'openUnfollowDialog'
		}),
		...mapActions(useFollowingStore, {
			setGirlId: 'setGirlId'
		}),
		async onClick() {
			try {
				this.isProcessing = true
				const host = this.$config.apiUrl
				if (this.isGuest) {
					window.location.href = `${host}guest/girls/${this.girlId}/subscribe?lang=${this.$i18n.locale}`
					return
				}
				this.setGirlId(this.girlId)
				if (this.isFollow) {
					await this.openUnfollowDialog()
					return
				}
				await this.$_startFollowing(this.girlId)
				await this.getFollowingGirls()
			} finally {
				this.isProcessing = false
			}
		}
	},
	computed: {
		isGuest() {
			return JSON.parse(this.$auth.remember())?.role === 'guest'
		}
	}
}
</script>

<style scoped lang="scss">
.following-btn {
	height: 20px;
	background: transparent;
	border: none;
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
	text-align: right;
	display: flex;
	justify-content: end;
	align-items: center;
	cursor: pointer;
}
</style>
