import qs from 'qs'
import axios from 'axios'
import { Router as router } from '@/router'
import { useModalStore } from '@/stores/modal'
import AppConfig from '@/config.class'
import axiosRetry from 'axios-retry'
import { i18n } from '@/plugins/i18n'

axiosRetry(axios, {
	retries: 3,
	retryDelay: (retryCount) => retryCount * 2000
})
const http = axios

const subscribeErrors = {
	'Unsubscribed: insufficient funds': 'error.insufficientFunds',
	'Unsubscribed: subscription failed': 'error.notConnect'
}

axios.interceptors.request.use((config) => {
	const isGuest = JSON.parse(localStorage.getItem('auth_remember'))?.role === 'guest'

	config.redirect = 'manual'
	config.paramsSerializer = (params) =>
		qs.stringify(params, {
			paramsSerializer: (p) => qs.stringify(p, { encode: false })
		})

	if (isGuest) {
		config.headers['mm-locale'] = i18n.global.locale
	}

	return config
})

http.interceptors.response.use(
	async (config) => {
		if (import.meta.env.DEV) {
			return config
		}
		return config
	},
	async (error) => {
		if (error.response) {
			if (error.response.status === 503) {
				await router.push({ name: 'service-unavailable' })
			}

			if (error.response.status === 401) {
				const errorMessage = error.response.data.message
				if (errorMessage.includes('Link is expired')) {
					await router.push({ name: 'qr-link-is-expired' })
				} else {
					window.location.replace(`${AppConfig.authRedirectUrl}&lang=${i18n.global.locale}`)
				}
			}

			if (error.response.status === 403) {
				if (error.response.data.redirect_to) {
					window.location.href = error.response.data.redirect_to
					return Promise.reject(error)
				}

				const errorMessage = error.response.data.message
				if (errorMessage.includes('Subscription Waiting')) {
					const errorRouteName = 'error-subscription-waiting-only-one-girl'
					const messageParts = errorMessage.split(' ')
					const girlNickname = messageParts[messageParts.length - 1]
					if (errorRouteName !== router.currentRoute.name) {
						await router.push({
							name: errorRouteName,
							query: { nickname: girlNickname }
						})
					}
				} else if (errorMessage.includes('Subscription Failed')) {
					const messageParts = errorMessage.split(' ')
					const temafonCode = messageParts[messageParts.length - 1]
					await router.push({
						name: 'error-subscription-failed-only-one-girl',
						params: { code: temafonCode }
					})
				} else if (errorMessage.includes('Unsubscribed')) {
					window.location.href = AppConfig.subsCardUrl
				} else if (Object.keys(subscribeErrors).includes(errorMessage)) {
					await router.push({
						name: 'error-subscribe',
						params: {
							message: subscribeErrors[errorMessage]
						}
					})
				} else {
					window.location.replace(`${AppConfig.authRedirectUrl}&lang=${i18n.global.locale}`)
				}
			}
		}

		if (error.code === 'ERR_NETWORK') {
			useModalStore().showNetworkError()
		}

		return Promise.reject(error)
	}
)

http.defaults.baseURL = AppConfig.apiUrl

export { http }

export default (app) => {
	app.axios = axios
	app.$http = axios

	app.config.globalProperties.axios = axios
	app.config.globalProperties.$http = axios
}
