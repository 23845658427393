<template>
	<input-file
		name="post"
		:hide-input="true"
		:rules="postMediaSchema"
		:accept="acceptFileExt"
		@click="onClick"
		@error="onError"
		@change="onChange"
		@processing="setProcessing($event)"
	/>
</template>

<script setup>
import { computed } from 'vue'
import { useCommonStore } from '@/stores/common'
import { useCreatePostStore } from '@/stores/createPost'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { postMediaSchema } from '@/helpers/validationSchemas'
import InputFile from '@/components/forms/InputFile.vue'

const commonStore = useCommonStore()
const createPostStore = useCreatePostStore()
const videoUploadStore = useVideoUploadStore()

const acceptFileExt = computed(() => {
	if (createPostStore.post.photo) {
		return 'image/*, .heic, .heif'
	}
	if (createPostStore.post.video) {
		return 'video/*'
	}
	return 'video/*, image/*, .heic, .heif'
})

function setProcessing(val) {
	createPostStore.setProcessing(val)
}

function onClick(e) {
	if (createPostStore.showCreatePostDialog) {
		return
	}
	if (videoUploadStore.progress > 0 || !commonStore.isMobileDevice) {
		e.preventDefault()
		createPostStore.openCreatePostDialog()
	}
}

async function onChange(file) {
	const [fileType] = file.type.split('/')
	createPostStore.openCreatePostDialog()
	if (fileType === 'image') {
		createPostStore.post.photo = file
		createPostStore.fullBase64 = file.url
		createPostStore.croppedBase64 = file.url
	} else if (fileType === 'video') {
		videoUploadStore.initUploader()
		videoUploadStore.addFile(file)
	}
}

function onError(error) {
	const { message, description } = error
	createPostStore.showValidationError(message, description)
}
</script>
