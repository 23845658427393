import Pusher from 'pusher-js/with-encryption'
import Echo from 'laravel-echo'
import { useMessengerStore } from '@/stores/messenger'
import AppConfig from '@/config.class'

window.Pusher = Pusher
function connect(token) {
	window.Echo = new Echo({
		broadcaster: 'reverb',
		key: AppConfig.reverbAppKey,
		wsHost: AppConfig.reverbHost,
		wsPort: AppConfig.reverbPort,
		wssPort: AppConfig.reverbPort,
		forceTLS: AppConfig.reverbScheme === 'https',
		enabledTransports: ['ws', 'wss'],
		authEndpoint: AppConfig.broadcastingAuth,
		auth: {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	})
}

function subscribe(role, id) {
	const subscriptionsChannel = window.Echo.channel(`subscriptions.${id}`)
	subscriptionsChannel.listen('.consumer.subscribed', () => {
		window.location.reload()
	})

	const channel = window.Echo.private(`messenger.${role}.${id}`)
	channel.listen('.new.message', (data) => {
		useMessengerStore().newMessage(data, id)
	})

	channel.listen('.message.archived', (data) => {
		useMessengerStore().removeRoomMessage(data)
	})

	channel.listen('.thread.archived', (data) => {
		useMessengerStore().removeThread(data)
	})

	channel.listen('.new.thread', (data) => {
		useMessengerStore().newThread(data)
	})
	channel.listen('.thread.read', (data) => {
		useMessengerStore().threadRead(data)
	})
	channel.listen('.paid-message.content-ready', (data) => {
		useMessengerStore().renewPaidMessage(data.messenger_thread_uuid, data.messenger_message_uuid)
	})

	channel.listen('.paid-message.payment-status-update', (data) => {
		useMessengerStore().renewPaidMessage(data.messenger_thread_uuid, data.messenger_message_uuid)
	})
	channel.listen('.unread_threads_count', (data) => {
		useMessengerStore().setUnreadThreadsCount(data.unread_threads_count)
	})
}

function subscribeThread(id) {
	const channel = window.Echo.channel(`presence-messenger.thread.${id}`)
	channel.listen('.message.edited', (data) => {
		useMessengerStore().editMessage(data)
	})
	channel.listen('.thread.avatar', (data) => {
		useMessengerStore().threadAvatar(data)
	})
	channel.listen('.reaction.added', (data) => {
		useMessengerStore().updateReactions(data)
	})
	channel.listen('.reaction.removed', (data) => {
		useMessengerStore().updateReactions(data)
	})
}

function checkConnection() {
	return window.Echo
}

export default { connect, subscribe, subscribeThread, checkConnection }
