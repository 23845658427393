import { defineStore } from 'pinia'
import { useProfileStore } from '@/stores/profile'

// ToDo перенести логику редиректа на подписку
export const useSubscribeStore = defineStore('subscribe', {
	state: () => ({
		profileStore: useProfileStore()
	}),
	actions: {
		checkSubscribeLimitState() {
			if (this.$config.subsModelLimit) {
				if (this.profileStore.subscriptionsData?.data?.girls?.length >= this.$config.subsModelLimit) {
					return true
				}
			}
			return false
		}
	}
})
