import { defineStore } from 'pinia'
import { useCommonStore } from '@/stores/common'
import { usePostStore } from '@/stores/post'
import { detectSafari, detectIOS } from '@/helpers/detectPlatform'

export const useModalStore = defineStore('modal', {
	state: () => ({
		appLoaderVisibility: false,
		showVerificationDialog: false,
		balanceNotification: false,
		networkErrorNotification: false,
		showVerificationNeedsDialog: false,
		showPWADialog: false,
		showLinkGreetingsDialog: false,
		showVerificationStatusDialog: false,
		showDetailPage: false,
		detailPageToComments: false,
		showCropModal: false,
		showCoverModal: false,
		showConsumersListsDialog: false,
		editVideoModal: false,
		showSubLimitModal: false,
		subLimitModalGirl: null,
		showSubLimitErrorModal: false,
		pwaText: '',
		naturalSize: null,
		panelSize: null,
		popUpSizeWidth: null,
		popUpSizeHeight: null,
		commonStore: useCommonStore(),
		postStore: usePostStore(),
		showConfirmAgeDialog: false,
		showUnfollowDialog: false,
		showUnsubscribeAndUnfollowDialog: false,
		showCommentsDialog: false,
		showPostLikesDialog: false
	}),
	actions: {
		openUnsubscribeAndUnfollowDialog() {
			this.showUnsubscribeAndUnfollowDialog = true
		},
		closeUnsubscribeAndUnfollowDialog() {
			this.showUnsubscribeAndUnfollowDialog = false
		},
		openUnfollowDialog() {
			this.showUnfollowDialog = true
		},
		closeUnfollowDialog() {
			this.showUnfollowDialog = false
		},
		openConfirmAgeDialog() {
			this.showConfirmAgeDialog = true
		},
		closeConfirmAgeDialog() {
			this.showConfirmAgeDialog = false
		},
		showAppLoader() {
			this.appLoaderVisibility = true
		},
		async openDetailPage(postId, publicationStatus, scrollToComment, post = null) {
			if (this.showDetailPage || publicationStatus !== 'published') {
				return
			}
			if (post) {
				await this.postStore.setPost(post)
			} else {
				await this.postStore.getPost(postId)
			}
			if (scrollToComment) {
				this.detailPageToComments = true
			}

			this.showDetailPage = true
		},
		setResizePopUp(naturalSize = null, panelSize = 0) {
			if (naturalSize && document.documentElement.clientWidth > 980) {
				this.panelSize = panelSize
				this.naturalSize = naturalSize
				this.setPopUpSizeByNaturalSize()
				window.addEventListener('resize', this.setPopUpSizeByNaturalSize)
			}
		},
		setPopUpSizeByNaturalSize() {
			const maxPopUpW = document.documentElement.clientWidth - 80
			const maxPopUpH = document.documentElement.clientHeight - 80
			const newW =
				this.getSize(maxPopUpH, this.naturalSize.naturalHeight, this.naturalSize.naturalWidth) + this.panelSize
			if (newW < maxPopUpW) {
				this.popUpSizeWidth = newW
				this.popUpSizeHeight = maxPopUpH
			} else {
				this.popUpSizeWidth = maxPopUpW
				this.popUpSizeHeight = this.getSize(
					maxPopUpW - this.panelSize,
					this.naturalSize.naturalWidth,
					this.naturalSize.naturalHeight
				)
			}
		},
		getSize(maxValue, smallPart, bigPart) {
			return maxValue / (smallPart / bigPart)
		},
		closeDetailPage() {
			window.removeEventListener('resize', this.setPopUpSizeByNaturalSize)
			this.removePopUpSize()
			this.showDetailPage = false
			this.detailPageToComments = false
		},
		removePopUpSize() {
			this.popUpSizeHeight = null
			this.popUpSizeWidth = null
			this.panelSize = null
			window.removeEventListener('resize', this.setPopUpSizeByNaturalSize)
		},
		hideAppLoader() {
			this.appLoaderVisibility = false
		},
		openVerificationDialog() {
			if (this.commonStore.isMobileDevice) {
				this.showVerificationDialog = true
			} else {
				this.showVerificationQrCodeDialog = true
			}
		},
		closeVerificationDialog() {
			this.showVerificationDialog = false
			this.showVerificationQrCodeDialog = false
		},
		showBalanceNotification() {
			this.balanceNotification = true
		},
		closeBalanceNotification() {
			this.balanceNotification = false
		},
		showNetworkError() {
			this.networkErrorNotification = true
			setTimeout(this.hideNetworkError, 3000)
		},
		hideNetworkError() {
			this.networkErrorNotification = false
		},
		openVerificationNeedsDialog() {
			this.showVerificationNeedsDialog = true
		},
		closeVerificationNeedsDialog() {
			this.showVerificationNeedsDialog = false
		},
		openPWADialog() {
			if (this.$config.buildPwa) {
				this.pwaText = this.getPwaText()
				if (this.pwaText && localStorage.getItem('pwaAsk') === null) {
					localStorage.setItem('pwaAsk', true)
					// this.$metrika.reachGoal('pwa-ask')
					this.showPWADialog = true
				}
			}
		},
		getPwaText() {
			if (detectIOS() && detectSafari() && !this.commonStore.deferredPrompt) {
				return 'safari'
			}
			if (detectIOS() && !this.commonStore.deferredPrompt) {
				return 'ios'
			}
			if (this.commonStore.deferredPrompt) {
				return 'canPWAInstall'
			}
			return ''
		},
		closePWADialog(installationStarted) {
			if (!installationStarted) {
				// this.$metrika.reachGoal('pwa-no')
			}
			this.commonStore.removeDeferredPrompt()
			this.showPWADialog = false
		},
		openLinkGreetingsDialog() {
			this.showLinkGreetingsDialog = true
		},
		closeLinkGreetingsDialog() {
			this.showLinkGreetingsDialog = false
		},
		openConsumersListsDialog() {
			this.showConsumersListsDialog = true
		},
		closeConsumersListsDialog() {
			this.showConsumersListsDialog = false
		},
		openVerificationStatusDialog() {
			this.showVerificationStatusDialog = true
		},
		closeVerificationStatusDialog() {
			this.showVerificationStatusDialog = false
		},
		openCropModal() {
			this.showCropModal = true
		},
		closeCropModal() {
			this.showCropModal = false
		},
		openCoverModal() {
			this.showCoverModal = true
		},
		closeCoverModal() {
			this.showCoverModal = false
		},
		openEditVideoModal() {
			this.editVideoModal = true
		},
		closeEditVideoModal() {
			this.editVideoModal = false
		},
		openSubLimitModal(girl = null) {
			this.subLimitModalGirl = girl
			this.showSubLimitModal = true
		},
		closeSubLimitModal() {
			this.showSubLimitModal = false
		},
		openSubLimitErrorModal(girl = null) {
			this.subLimitModalGirl = girl
			this.showSubLimitErrorModal = true
		},
		closeSubLimitErrorModal() {
			this.showSubLimitErrorModal = false
		},
		openCommentsDialog() {
			this.showCommentsDialog = true
		},
		closeCommentsDialog() {
			this.showCommentsDialog = false
		},
		openPostLikesDialog() {
			this.showPostLikesDialog = true
		},
		closePostLikesDialog() {
			this.showPostLikesDialog = false
		}
	}
})
