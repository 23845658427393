<template>
	<dialog-component class="sub-limit-dialog" @close="$emit('close')" :is-loading="isLoading">
		<template #header-title>
			{{ $t('subscription.limit') }}
		</template>
		<div class="sub-limit">
			<div class="top-section">
				<div
					class="user-avatar"
					:style="{ 'background-image': `url('${profile?.avatar || subLimitModalGirl?.avatar}')` }"
				/>
				<div class="user-descr">
					{{ $t('subscription.fan') }}
				</div>
			</div>
			<div class="sub-limit-list history flex row start">
				<div
					v-for="girl in subscriptionsData?.data?.girls?.filter((item) => item?.is_renewable === true)"
					:key="`sl${girl.id}`"
				>
					<div
						@click="onSelect(girl.id)"
						class="history-item"
						:class="{
							active: girl.id === pickGirlId
						}"
					>
						<div class="image-container relative">
							<div class="image placeholder-bg">
								<img v-if="girl.avatar_url" :src="girl.avatar_url" alt="Аватар" />
							</div>
						</div>
						<div class="history-item-name">{{ girl.model_name }}</div>
					</div>
				</div>
			</div>
		</div>

		<slot v-if="!isLoading" />
		<div v-else class="sub-limit-waiting">
			<loader-component />
			<div>{{ $t('subscription.waitLittle') }}</div>
		</div>
		<template v-if="!isLoading" #dialog-footer>
			<div class="confirmation-dialog-buttons">
				<ButtonComponent color="white" @click="$emit('decline')">
					{{ $t('subscription.decline') }}
				</ButtonComponent>
				<ButtonComponent color="transparent" :disabled="isWaitingPickGirl" @click="onUnsubscribeSubLimit">
					{{ $t('subscription.accept') }}
				</ButtonComponent>
			</div>
		</template>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useProfileStore } from '@/stores/profile'
import { useModalStore } from '@/stores/modal'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
	name: 'SubLimitDialog',
	components: { DialogComponent, ButtonComponent, LoaderComponent },
	props: {
		disabled: {
			type: Boolean,
			default: () => false
		}
	},
	data() {
		return {
			pickGirlId: null,
			isWaitingPickGirl: true
		}
	},
	computed: {
		...mapState(useGirlStore, { profile: 'profile', isLoading: 'isLoading' }),
		...mapState(useProfileStore, {
			consumerId: 'id',
			subscriptionsData: 'subscriptionsData'
		}),
		...mapState(useModalStore, ['subLimitModalGirl']),
		subscribeLink() {
			const host = this.$config.apiUrl
			return `${host}consumers/${this.consumerId}/girls/${this.subLimitModalGirl?.id}/subscribe`
		}
	},
	methods: {
		...mapActions(useModalStore, {
			closeSubLimitModal: 'closeSubLimitModal'
		}),
		...mapActions(useGirlStore, {
			unsubscribe: 'unsubscribe'
		}),
		async onUnsubscribeSubLimit() {
			try {
				await this.unsubscribe(this.pickGirlId)
			} finally {
				this.closeSubLimitModal()
				window.location.href = this.subscribeLink
			}
		},
		onSelect(id) {
			this.pickGirlId = id
			this.isWaitingPickGirl = false
		}
	}
}
</script>

<style lang="scss">
.sub-limit-dialog {
	color: $color-gray;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;

	& .dialog {
		min-height: 478px;
	}

	&-buttons {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	& .sub-limit {
		& .top-section {
			display: flex;
			align-items: center;

			& .user-avatar {
				margin-right: 10px;
			}

			& .user-descr {
				color: $color-black;
				background-color: $color-gray-light-additional-third;
				padding: 4px 8px;
				border-radius: 3px 12px 12px 12px;
			}
		}

		&-list {
			margin-top: 20px;

			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 12px;
			}

			& .placeholder-bg {
				background-image: url('@/assets/images/model-avatar-default.png');
				background-size: 140%;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		&-waiting {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
		}
	}
}
</style>
