<template>
	<div class="user-with-likes">
		<router-link :to="userLink" @click="$emit('click-link')" class="flex row start">
			<avatar-circle :image-path="avatar" />
			<div class="flex column center">
				<div class="user-with-likes-name">
					{{ nickname }}
					<badge-fan v-if="showFanBadge" />
					<template v-else-if="showFollowerBadge">
						<badge-following />
						<badge-bank-card v-if="showCreditCardIcon" />
					</template>
				</div>
			</div>
		</router-link>
		<div class="user-with-likes-right">
			<like-counter>
				{{ total_likes_by_posts }}
			</like-counter>
			<button
				type="button"
				v-if="showOptionsButton"
				class="user-with-likes-options-button"
				ref="userSettings"
				@click.stop="showOptionsDialog($refs.userSettings)"
			>
				<svg-icon name="ellipsis-vertical" />
			</button>
		</div>
	</div>
</template>

<script>
import AvatarCircle from '@/components/AvatarCircle.vue'
import LikeCounter from '@/components/LikeCounter.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import BadgeFan from '@/components/BadgeFan.vue'
import BadgeFollowing from '@/components/BadgeFollowing.vue'
import isOwnProfileMixin from '@/mixins/isOwnProfileMixin'
import BadgeBankCard from '@/components/BadgeBankCard.vue'
import eventHelper from '@/helpers/event.helper.ts'

export default {
	name: 'UserWithLikes',
	mixins: [isOwnProfileMixin],
	components: {
		BadgeBankCard,
		BadgeFollowing,
		BadgeFan,
		AvatarCircle,
		LikeCounter,
		SvgIcon
	},
	props: {
		id: { type: Number, required: true },
		avatar: { type: String, default: '' },
		nickname: { type: String, default: '' },
		userLink: {
			type: Object,
			default: () => {}
		},
		total_likes_by_posts: { type: Number, default: 0 },
		has_acting_girl_following: { type: Boolean, default: false },
		has_acting_girl_subscription: { type: Boolean, default: false },
		is_adult: { type: Boolean, default: false },
		showOptionsButton: { type: Boolean, default: true }
	},
	computed: {
		showFanBadge() {
			if (this.$config.allowFollowing) {
				return this.has_acting_girl_subscription
			}
			return this.$_isOwnProfile && this.has_acting_girl_subscription
		},
		showFollowerBadge() {
			return this.$config.allowFollowing && this.has_acting_girl_following
		},
		showCreditCardIcon() {
			return this.isGirl && this.is_adult
		},
		isGirl() {
			return this.$auth.check('girl')
		}
	},
	methods: {
		showOptionsDialog(el) {
			eventHelper.emit('setPopupData', {
				popup: 'consumerOptions',
				position: JSON.parse(JSON.stringify(el.getBoundingClientRect())),
				settings: {
					id: this.id ?? null
				},
				dropdownMode: true
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.user-with-likes {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 20px;

	&-name {
		display: flex;
		align-items: center;
		font-style: normal;
		font-weight: 700;
		font-size: 14px;
		line-height: 20px;
		color: $color-white;
		gap: 5px;
	}

	&-right {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	&-options-button {
		background: transparent;
		border: none;
		color: $color-gray;
		font-size: 20px;
		text-align: right;
	}

	& .avatar-circle {
		margin-right: 12px;
	}
}

.options {
	&-list {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&-item {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		text-align: left;
		color: $color-white;
		background: transparent;
		padding: 4px 0;
		border: none;
	}
}
</style>
