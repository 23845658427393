import { createI18n } from 'vue-i18n'
import { ruPluralRule } from '@/config/i18n.rules'
import AppConfig from '@/config.class'
import ru from '@/locales/ru.json'
import en from '@/locales/en.json'

export const i18n = createI18n({
	locale: AppConfig.defaultLanguage,
	fallbackLocale: AppConfig.defaultLanguage,
	silentTranslationWarn: true,
	pluralizationRules: {
		ru: ruPluralRule
	},
	messages: {
		ru,
		en
	}
})
