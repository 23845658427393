import { useToast } from 'vue-toastification'
import { i18n } from '@/plugins/i18n'

const toast = useToast()
const $t = i18n.global.t

export const copyToClipboard = async (valueToCopy, successToast = 'linkCopied') => {
	const type = 'text/plain'
	const blob = new Blob([valueToCopy], { type })

	try {
		const cbi = new ClipboardItem({
			[type]: blob
		})
		await navigator.clipboard.write([cbi])
		toast($t(successToast))
	} catch (error) {
		toast($t('error.linkUncopied'))
	}
}
