<template>
	<div class="comment">
		<div class="avatar-circle placeholder-bg" />
		<div class="w-full">
			<span class="comment-nickname placeholder w-75" />
			<div class="release placeholder w-50" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'PlaceholderComment'
}
</script>
