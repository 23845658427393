import { mixed, number, ref, string } from 'yup'
import configClass from '@/config.class'
import { i18n } from '@/plugins/i18n'

export const nicknameSchema = string()
	.required(i18n.global.t('error.nicknameRequired'))
	.min(4, ({ min }) => i18n.global.t('error.nicknameMin', { min }))
	.max(14, ({ max }) => i18n.global.t('error.nicknameMax', { max }))

export const aboutYourselfSchema = string()
	.min(5, ({ min }) => i18n.global.t('error.aboutYourselfMin', { min }))
	.max(600, ({ max }) => i18n.global.t('error.aboutYourselfMax', { max }))

export const imageSchema = mixed()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif'])
	.aspectRatio(3)
	.size(configClass.maxImgSize)

export const emailSchema = string().required('emailRequired').email('email')

export const passwordSchema = string()
	.required(i18n.global.t('error.passwordRequired'))
	.min(6, ({ min }) => i18n.global.t('error.passwordMin', { min }))
	.max(64, ({ max }) => i18n.global.t('error.passwordMax', { max }))
	.password(i18n.global.t('error.password'))

export const passwordConfirmationSchema = string()
	.required(i18n.global.t('error.passwordConfirmationRequired'))
	.oneOf([ref('password'), null], i18n.global.t('error.passwordConfirmationOneOf'))

export const postMediaSchema = mixed()
	.required()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif', 'mp4', 'mov'], ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.postMediaFormatDescription')
	}))
	.aspectRatio(3, ({ defaultMessage }) => ({
		message: defaultMessage
	}))
	.minDimensions([1000, 217], ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.minDimensionsDescription')
	}))
	.imageSize(configClass.maxImgSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.tryReduceFileSize')
	}))
	.videoSize(configClass.maxVideoSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.tryReduceFileSize')
	}))
	.minVideoDuration(3, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.minVideoDurationDescription')
	}))
	.maxVideoDuration(30, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.maxVideoDurationDescription')
	}))

export const consumersListSchema = string()
	.required(i18n.global.t('error.consumersListRequired'))
	.max(100, ({ max }) => i18n.global.t('error.consumersListMax', { max }))
	.noOnlySpaces()

export const paidMessagePriceSchema = number()
	.required(i18n.global.t('error.paidMessagePriceRequired'))
	.transform((value) => (Number.isNaN(value) ? null : value))
	.default(0)
	.min(99, ({ min }) => i18n.global.t('error.paidMessagePriceMin', { min }))
	.max(10000, ({ max }) => i18n.global.t('error.paidMessagePriceMax', { max }))

export const paidMessagePaidTextSchema = string()
	.required(i18n.global.t('error.paidMessagePaidTextRequired'))
	.noOnlySpaces()
	.noLinksOrEmail()

export const paidMessageAdditionalTextSchema = string().noOnlySpaces().noLinksOrEmail().nullable()

export const paidMessageFilesHelperSchema = string()
	.transform((value) => value.toString())
	.required(i18n.global.t('error.paidMessageFilesHelperRequired'))

export const paidMessageFilesSchema = mixed()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif', 'mp4', 'mov', 'avi', 'mpeg', 'wav'], () => ({
		message: i18n.global.t('error.paidMessageFilesMessage'),
		description: i18n.global.t('error.paidMessageFilesDescription')
	}))
	.imageSize(configClass.maxImgSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.tryReduceFileSize')
	}))
	.videoSize(configClass.maxVideoSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.tryReduceFileSize')
	}))

export const paidMessageCoverSchema = mixed()
	.notRequired()
	.ext(['jpg', 'jpeg', 'png', 'heic', 'heif'], ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.postMediaFormatDescription')
	}))
	.imageSize(configClass.maxImgSize, ({ defaultMessage }) => ({
		message: defaultMessage,
		description: i18n.global.t('error.tryReduceFileSize')
	}))
