<template>
	<div class="list">
		<div class="list__heading">
			<span>{{ t('locale.selectedLocale') }}:</span>
			<language-with-flag :locale="locale" />
		</div>
		<div class="list__body">
			<div class="list__item" v-for="l in locales" :key="l" @click="handleClick(l)">
				<language-with-flag :locale="l" />
				<svg-icon name="checkmark" size="24px" v-if="l === locale" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCommonStore } from '@/stores/common'
import { useGirlStore } from '@/stores/girl'
import { useConsumerStore } from '@/stores/consumer'
import { useCurrentUserStore } from '@/stores/user'
import LanguageWithFlag from '@/components/LanguageWithFlag.vue'
import SvgIcon from '@/components/SvgIcon.vue'

const { t, locale, availableLocales } = useI18n()
const locales = ref(null)
const { setProfileLocale } = useCommonStore()
const { getGirlProfile, profile: girProfile } = useGirlStore()
const { getConsumer, profile: consumerProfile } = useConsumerStore()
const { isGirl, isConsumer } = useCurrentUserStore()

const emit = defineEmits(['click'])

onMounted(() => {
	const l = availableLocales.filter((item) => item !== locale.value)
	locales.value = [locale.value, ...l]
})

const handleClick = async (data) => {
	if (data !== locale.value) {
		locale.value = data
		localStorage.setItem('currentLocale', locale.value)
		await setProfileLocale(data)
		if (isGirl) await getGirlProfile(girProfile.id, true)
		if (isConsumer) await getConsumer(consumerProfile.id, true)
	}
	emit('click', data)
}
</script>

<style lang="scss" scoped>
.list {
	&__heading {
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		column-gap: 20px;
		padding: 20px;
	}

	&__body {
		display: flex;
		flex-flow: column;
		row-gap: 15px;
		border-top: 1px solid #ffffff1a;
		padding: 20px 0 0;
	}

	&__item {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		min-height: 36px;
		padding: 3px 10px;

		&:hover {
			cursor: pointer;
			background-color: #25272c;
			transition: background-color 0.2s ease-in-out;
			border-radius: 9px;
		}

		svg {
			fill: #ab6381;
		}
	}

	&__heading,
	&__body {
		display: flex;
	}
}
</style>
