import { defineStore } from 'pinia'
import { getProfile as getGirlProfile } from '@/http/girl/profile/profile'
import { getProfile as getConsumerProfile } from '@/http/consumer/profile'

export const useCurrentUserStore = defineStore('current-user', {
	state: () => ({
		isAuthorised: false,
		currentUser: null,
		userRole: null
	}),
	getters: {
		isGirl: (state) => state.userRole === 'girl',
		isConsumer: (state) => state.userRole === 'consumer'
	},
	actions: {
		setUserRole(role) {
			this.userRole = role
		},
		setCurrentUser(user) {
			this.currentUser = user
		},
		setIsAuthorised(state) {
			this.isAuthorised = state
		},
		// ToDo внедрить Api Store и переписать логику всех методов с запросами на него
		async fetchCurrentUser() {
			if (this.userRole === 'consumer') {
				const userData = await getConsumerProfile()
				this.setCurrentUser(userData)
			}
			if (this.userRole === 'girl') {
				const userData = await getGirlProfile()
				this.setCurrentUser(userData)
			}
		}
	}
})
