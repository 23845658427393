import { defineStore } from 'pinia'
import messengerApi from '@/http/messenger'
import messageSound from '@/assets/sounds/notify_tone.mp3'
import threadSound from '@/assets/sounds/message_tone.mp3'

const audioMessage = new Audio(messageSound)
const audioThread = new Audio(threadSound)

export const useMessengerStore = defineStore('messenger', {
	state: () => ({
		messagesList: [],
		roomsList: [],
		roomsNextPageId: null,
		roomsLoaded: false,
		loadingRooms: false,
		nextPageLoaded: false,
		activeThread: null,
		recommendedList: [],
		searchedUsers: [],
		roomName: '',
		containerScrolled: false,
		unreadThreadsCount: 0,
		welcomeMsgTimeout: 0,
		isRoomsInitialized: false,
		roomsFilter: null
	}),
	getters: {
		getRoomsFilter: (state) => state.roomsFilter
	},
	actions: {
		checkFiltersMatch(thread) {
			const isAdult = thread.resources.recipient.base.is_adult
			const isSubscribed = thread.participants_has_subscription
			const isFollower = thread.participants_has_following && !isSubscribed && !isAdult
			const isFollowerWithCard = thread.participants_has_following && !isSubscribed && isAdult

			if (isSubscribed && this.roomsFilter.is_subscribed === 1) return true
			if (isFollower && this.roomsFilter.is_adult_and_follower === 0) return true
			if (isFollowerWithCard && this.roomsFilter.is_adult_and_follower === 1) return true
			return false
		},
		setRoomsFilter(filter) {
			this.roomsFilter = filter
		},
		resetRoomsFilter() {
			this.roomsFilter = null
		},
		setIsRoomsInitialized(state) {
			this.isRoomsInitialized = state
		},
		setUnreadThreadsCount(count) {
			this.unreadThreadsCount = Number(count)
		},
		decrementUnreadThreadsCount() {
			if (this.unreadThreadsCount > 0) this.unreadThreadsCount -= 1
		},
		async getUnreadThreadsCount() {
			try {
				const { unread_threads_count } = await messengerApi.getUnreadThreadsCount()
				this.unreadThreadsCount = unread_threads_count
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
		},
		async getRoomsList(params, reset = false) {
			try {
				if (!this.isRoomsInitialized || reset) {
					this.roomsLoaded = false
					this.loadingRooms = true
					const result = await messengerApi.getThreadsList(params)
					this.roomsNextPageId = result.meta.next_page_id
					if (reset) {
						this.roomsList = result.data
					} else {
						this.roomsList = Object.values(
							[...this.roomsList, ...result.data].reduce((acc, el) => {
								acc[el.id] = el
								return acc
							}, {})
						)
					}
				}
				this.setIsRoomsInitialized(true)
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			} finally {
				this.loadingRooms = false
			}
		},
		async getMoreRooms(params) {
			try {
				if (this.roomsNextPageId) {
					const result = await messengerApi.getMoreThreads(this.roomsNextPageId, params)
					this.roomsList = Object.values(
						[...this.roomsList, ...result.data].reduce((acc, el) => {
							acc[el.id] = el
							return acc
						}, {})
					)
					this.roomsNextPageId = result.meta.next_page_id
				}
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			} finally {
				if (!this.roomsNextPageId) {
					this.roomsLoaded = true
				}
			}
		},
		async getRecommendedGirls() {
			try {
				this.recommendedList = await messengerApi.getRecommendedListGirl()
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
		},
		async getRecommendedConsumers() {
			try {
				this.recommendedList = await messengerApi.getRecommendedListConsumer()
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
		},
		async searchUsers(value) {
			if (!value || value.length < 2) {
				this.searchedUsers = []
				return
			}
			try {
				const result = await messengerApi.search(value.replace(/[&\\#,+()$~%.'":*?]/g, ''))
				this.searchedUsers = result.data
			} catch (e) {
				// eslint-disable-next-line
				console.trace(e)
			}
		},
		resetSearch() {
			this.searchedUsers = []
		},
		resetActiveRoom() {
			this.activeThread = null
			this.roomName = ''
		},
		toggleActiveThread(thread) {
			this.activeThread = thread
		},
		updateMessages(data) {
			this.messagesList = data
		},
		updateRooms(data) {
			this.roomsList = data
		},
		updateRoomsNewThread(newThread) {
			this.roomsList = [newThread, ...this.roomsList]
		},
		newMockMessage(msg) {
			this.messagesList.splice(this.messagesList.length, 0, msg)
		},
		newMessage(msg, id) {
			if (this.activeThread && this.activeThread.id === msg.thread_id) {
				let index = 0
				if (msg.type_verbose === 'PAID_MESSAGE') {
					index = this.messagesList.findIndex((m) => m?.paid_message?.id === msg.paid_message.id)
				} else {
					index = this.messagesList.findIndex((m) => String(m.temporary_id) === String(msg.temporary_id))
				}

				if (index === -1) {
					this.messagesList.splice(this.messagesList.length, 0, msg)
				} else {
					this.messagesList.splice(index, 1, msg)
				}
			}

			this.updateRoomMessage(msg)

			if (msg.owner_id !== id) {
				this.playSound()
			}
		},
		editMessage(msg) {
			const index = this.messagesList.findIndex((m) => m.id === msg.id)
			if (this.messagesList[index]) this.messagesList.splice(index, 1, msg)
			this.updateRoomMessage(msg)
		},
		async updateRoomMessage(msg) {
			if (this.roomsList.length === 0) return
			const index = this.roomsList.findIndex((thread) => thread.id === msg.thread_id)

			if (index === -1) {
				await this.getThreadById(msg.thread_id)
				return
			}

			const room = this.roomsList[index]
			room.resources.latest_message = msg
			this.roomsList.splice(index, 1)
			this.roomsList.splice(0, 0, room)

			if (msg.owner.name === room.resources?.recipient?.name) {
				room.unread = true
				room.unread_count += 1
			}
		},
		async removeRoomMessage(msg) {
			const index = this.messagesList.findIndex((m) => m.id === msg.message_id)
			if (index !== -1) {
				this.messagesList.splice(index, 1)
			}

			const roomIndex = this.roomsList.findIndex((room) => room.id === msg.thread_id)
			if (roomIndex !== -1) {
				if (this.roomsList[roomIndex].resources.latest_message.id === msg.message_id) {
					const res = await messengerApi.getMessages({ threadId: msg.thread_id })
					const arr = [...res.data.reverse()]
					if (res.data.length) {
						this.roomsList[roomIndex].resources.latest_message = arr[res.data.length - 2]
					}
				}
			}
		},
		removeThread(data) {
			const index = this.roomsList.findIndex((thread) => thread.id === data.thread_id)
			if (index !== -1) {
				this.roomsList.splice(index, 1)
			}
		},
		threadAvatar(data) {
			const index = this.roomsList.findIndex((thread) => thread.id === data.thread.id)
			if (index !== -1) {
				this.roomsList[index].avatar = data.thread.avatar
			}
		},
		async newThread(data) {
			// const newThread = await messengerApi.getThread(data.thread.id)
			// this.roomsList.splice(0, 0, newThread)
			await this.getThreadById(data.thread.id)
			this.playSound()
		},
		async markRead(threadId) {
			const room = this.roomsList.find((r) => r.id === threadId)
			room.unread = false
			await messengerApi.markRead(threadId)
			room.unread_count = 0
		},
		async updateReactions(data) {
			const idx = this.messagesList.findIndex((msg) => msg.id === data.message_id)
			if (idx !== -1) {
				const originalMessage = this.messagesList[idx]
				originalMessage.reactions = await messengerApi.getReactions(originalMessage.thread_id, data.message_id)
				this.messagesList.splice(idx, 1, originalMessage)
			}
		},
		async threadRead(data) {
			const room = this.roomsList.find((thread) => thread.id === data.thread_id)
			room.resources.latest_message.is_read = true
			this.decrementUnreadThreadsCount()
			this.messagesList
				.filter(
					(message) =>
						message?.owner_id === room.resources.latest_message.owner_id && message?.thread_id === data.thread_id
				)
				.map((message) => {
					message.is_read = true
					return message
				})
		},
		playSound() {
			if (this.activeThread) {
				audioMessage.play()
			} else {
				audioThread.play()
			}
		},
		renewMessage(message) {
			const idx = this.messagesList.findIndex((msg) => msg.id === message.id)
			if (idx !== -1) {
				this.messagesList.splice(idx, 1, message)
			}
		},
		async createNewPaidMessage(type) {
			const { id } = await messengerApi.createPaidMessage(type)
			return id
		},
		updatePaidMessage(messageId, data) {
			return messengerApi.updatePaidMessage(messageId, data)
		},
		updateUploadingProgress(temId, progressValue) {
			let messageIndex = null
			const msg = this.messagesList.find((m, i) => {
				if (m.temporary_id === temId) {
					messageIndex = i
					return m
				}
				return null
			})
			if (messageIndex !== -1) {
				msg.progress = progressValue
				this.messagesList.splice(messageIndex, 1, msg)
			}
		},
		sendPaidMessage(threadId, messageId) {
			return messengerApi.publishPaidMessage(threadId, messageId)
		},
		uploadPaidMedia(type, messageId, data, signal) {
			try {
				return messengerApi.uploadPaidMedia(type, messageId, data, signal)
			} catch (e) {
				throw new Error(e)
			}
		},
		async deleteFile(messageId, attachmentId) {
			await messengerApi.deleteMedia(messageId, attachmentId)
		},
		setPaidMessageCover(messageId, data) {
			return messengerApi.setCoverForPaidMessage(messageId, data)
		},
		async deletePaidMessage(paidMessageId, threadId, messageId) {
			await messengerApi.deletePaidMessage(paidMessageId, threadId, messageId)
		},
		async renewPaidMessage(threadId, messageId) {
			const message = await messengerApi.getMessage(threadId, messageId)
			this.renewMessage(message)
		},
		async getNewMessages(signal) {
			const { unread, unread_count } = await messengerApi.getThread(this.activeThread.id)
			if (unread) {
				const res = await messengerApi.getMessages({ threadId: this.activeThread.id }, signal)
				const messages = res.data
				const newMessages = messages.slice(0, unread_count)
				this.updateMessages([...this.messagesList, ...newMessages.reverse()])
			}
		},
		async getPaidMessageFreeAccess(message) {
			try {
				const index = this.messagesList.findIndex((m) => m.id === message.id)
				message.paid_message.payment_status = 'pending_payment'
				this.messagesList.splice(index, 1, message)
				message.paid_message = await messengerApi.getPaidMessageFreeAccess(message.id)
				this.messagesList.splice(index, 1, message)
			} catch (e) {
				throw new Error(e)
			}
		},
		async sendMultimediaMessage(threadId, params) {
			await messengerApi.sendMultimediaMessage(threadId, params)
		},
		setWelcomeMsgTimeout(timeout = 0) {
			this.welcomeMsgTimeout = timeout
		},
		async getThreadById(id) {
			const newThread = await messengerApi.getThread(id)
			if (this.roomsFilter && !this.checkFiltersMatch(newThread)) return
			this.roomsList.splice(0, 0, newThread)
		},
		resetRoomList() {
			this.roomsList = []
		}
	}
})
